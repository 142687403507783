import { useState, useEffect, Fragment } from 'react';
import { useDataProvider } from 'react-admin';

import styles from './styles.module.scss';

const NewOrdersWidget = () => {

  const dataProvider = useDataProvider();
  const [newPayAtDoorCount, setPayAtDoorCount] = useState(0);
  const [newStripeOrdersCount, setNewStripeOrdersCount] = useState(0);

  useEffect(() => {
    const GetVanSlots = async () => {
      try {
        const responsePayAtDoor = await dataProvider.getList("orders",
          {
            filter: {
              "orderType.orderTypeName": "PRODUCT",
              "orderStatus.orderStatusName": "CREATED"
            },
            pagination: { page: 1, perPage: 1 },
          },
        );
        setPayAtDoorCount(responsePayAtDoor?.total || responsePayAtDoor?.data?.length || 0);
        const responseStripe = await dataProvider.getList("orders",
          {
            filter: {
              "orderType.orderTypeName": "PRODUCT",
              "orderStatus.orderStatusName": "PAID"
            },
            pagination: { page: 1, perPage: 1 },
          },
        );
        setNewStripeOrdersCount(responseStripe?.total || responseStripe?.data?.length || 0);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetVanSlots();
  }, [dataProvider]);

  return (
    <Fragment>
      <div className={styles.countText}>
        {newPayAtDoorCount + newStripeOrdersCount}
      </div>
      <div className={styles.subText}>Pay At Door: {newPayAtDoorCount}</div>
      <div className={styles.subText}>Stripe orders: {newStripeOrdersCount}</div>
    </Fragment>
  );
};

export default NewOrdersWidget;
