import React, { Fragment, useState, useEffect } from 'react';
import {
  Button,
  ChipField,
  Datagrid,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FormTab,
  FunctionField,
  Labeled,
  List,
  NumberInput,
  Resource,
  SelectColumnsButton,
  SelectInput,
  TabbedForm,
  TextField,
  TopToolbar,
  useCreatePath,
  useDataProvider,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { availablePickupStatus, GetSlotFormatedDate, AddressSnapshot, ShipmentDeliveryType } from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';
import { SaveButton, Toolbar } from 'react-admin';

import styles from "./styles.module.scss";

const GetUserFullname = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [userFullname, setFullName] = useState({});

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        res = await dataProvider.getOne('users', { id: record?.userAddressSnapshot?.userId });
      } catch (error) {
        console.warn('Error: ', error)
      }
      setFullName(status => {
        return { status, ...{ name: res?.data?.firstName + ' ' + res?.data?.lastName}}
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return <span>{userFullname.name}</span>
}

export const CopyUserPhone = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.userAddressSnapshot?.phoneNumber)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

const ProductsInPickup = (props) => {
  const record = useRecordContext(props);
  const createPath = useCreatePath();


  return record?.products.map((product, index) => {
    const productPath = createPath({ resource: 'products', type: 'edit', id: product.id });

    return (
      <tr>
        <td>{index + 1}</td>
        <td>{product.code}</td>
        <td>{product.name}</td>
        <td><EditButton component={Link} variant="contained" to={productPath} label="Edit" /></td>
        <td>{product.price}</td>
        <td>{product.salePrice}</td>
        <td>{product.productStatus}</td>
      </tr>
      );
  });
}

const GetUserAddress = (props) => {
  const record = useRecordContext(props);

  return (
    <span>
      {record.userAddressSnapshot.street + " " + record.userAddressSnapshot.streetNumber}
    </span>
  );
}

const GoToUser = (props) => {
  const record = useRecordContext(props);
  const createPath = useCreatePath();
  if (!record) {
    return null;
  }
  const userPath = createPath({ resource: 'users', type: 'edit', id: record?.userAddressSnapshot?.userId });

  return (
    <div>
      {GetUserFullname()} - <Link to={userPath}>Show User</Link>
    </div>
    );
};

const UserEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const PickupsFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Pickup ID" source="id" alwaysOn />
    <NumberInput label="Number of Bags" source="numberOfBagsToReturn" alwaysOn />
    <SelectInput label="Status" source="bagPickupStatus.bagPickupStatusName" choices={availablePickupStatus} resettable alwaysOn/>
  </Filter>
);

const PickupListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions === 'SUPER_ADMIN' && (<ExportButton maxResults={99999999} />)}
    </TopToolbar>
  );
};

export const PickupList = props => {
  return (
    <List
      pagination={<AdminPagination />}
      perPage={25}
      {...props}
      bulkActionButtons={false}
      filters={<PickupsFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<PickupListActions />}
    >
      <Datagrid rowClick={false}>
        <TextField source="id" label="Pickup ID" />
        <FunctionField label="Status" render={
          (record) => {
            let dot = "";
            const status = record?.bagPickupStatus?.bagPickupStatusName;
            if (status=== "PICKED_UP") {
              dot = "🔵";
            } else if (status === "FAILED") {
              dot = "🔴";
            } else if (status === "COMPLETED") {
              dot = "🟢";
            } else if (status === "CREATED") {
              dot = "🟣";
            } else if (status === "CANCELLED") {
              dot = "⭕ ";
            }
            return <Chip source="bagPickupStatus.bagPickupStatusName" label={dot + " " + status} />;
          }
        }/>
        <EditButton label="Edit" variant="contained" />
        <GetUserFullname label="Full name" />
        <FunctionField
          render={(record) => {
            return (
              <Fragment>
                <CopyUserPhone />
                {record?.userAddressSnapshot?.phoneNumber}
              </Fragment>
            );
          }}
          label="Phone Number"
        />
        <GetUserAddress label="Address" />
        <TextField source="userAddressSnapshot.postcode" label="Post Code" />
        <ChipField source="numberOfBagsToReturn" label="Number of bags" />
        <FunctionField label="Slot (VanSlot ID)" render={
          (record) => {
            const shipmentId = record?.shipmentTime?.id;
            return shipmentId === 1
            ? <span>Delivered in store</span>
            : <span>{GetSlotFormatedDate() + " (" + shipmentId + ")"}</span>
          }
        }/>
      </Datagrid>
    </List>
  )
};

export const PickupEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm warnWhenUnsavedChanges toolbar={<UserEditToolbar />}>
        <FormTab warnWhenUnsavedChanges label="Details">
          <Labeled label="Pickup ID:">
            <TextField source="id" />
          </Labeled>
          <Labeled label="Seller:">
            <GoToUser />
          </Labeled>
          <Labeled label="Delivery Type:">
            <ShipmentDeliveryType />
          </Labeled>
          <Labeled label="Address:">
            <AddressSnapshot />
          </Labeled>
          <Labeled label="Number of bags:">
            <ChipField source="numberOfBagsToReturn" />
          </Labeled>
          <Labeled label="Van Slot Date:">
            <FunctionField render={GetSlotFormatedDate} />
          </Labeled>
          <Labeled label="Status:">
            <SelectInput source="bagPickupStatus.bagPickupStatusName" choices={availablePickupStatus} />
          </Labeled>
        </FormTab>
        <FormTab label="Items">
          <table className={styles.productTable}>
            <col></col>
            <colgroup span="7"></colgroup>
            <th colspan="7">Items included in Pickup bags</th>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>SKU</th>
              <th>Action</th>
              <th>Price</th>
              <th>Sale price</th>
              <th>Status</th>
            </tr>
            <ProductsInPickup />
          </table>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const Pickups = () => {
  return (
    <Resource
      name="pickups"
      icon={OrderIcon}
      options={{ label: 'Pickups' }}
      list={PickupList}
      edit={PickupEdit}
    />
  );
}

export default Pickups;
