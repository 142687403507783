import React from 'react';
import {
  Create,
  DateTimeInput,
  FormDataConsumer,
  maxLength,
  maxValue,
  minLength,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SelectInput
} from 'react-admin';

import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons
} from 'ra-input-rich-text';

import { PROMOTIONAL_CODES_STATUS } from 'src/utils/promotionalCode';
import { validatePromotionalCode, validateInteger } from 'src/resources/PromotionalCodes/common';

export const PromotionalCodeCreate = props => {

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <div className="helper-text">* User-friendly name that will be communicated and used by Users.</div>
        <TextInput
          source="code"
          id="promotionalCodeCode"
          validate={[required(), minLength(6), maxLength(50), validatePromotionalCode]}
          resettable
        />
        <div className="helper-text">* The date/time the promotion code starts to be valid:</div>
        <DateTimeInput label='Start Date:' source='startDate' locales="el-GR" validate={[required()]} />
        <div className="helper-text">* The date/time the promotion code stops to be valid:</div>
        <DateTimeInput label='End Date:' source='endDate' locales="el-GR" validate={[required()]} />
        <div className="helper-text">* The percentage of discount that the promotional code will apply:</div>
        <NumberInput source="discountPercentage" validate={[required(), minValue(0), maxValue(99), validateInteger]} step={1}/>
        <div className="helper-text">* Maximum amount of discount:</div>
        <NumberInput source="discountMaxAmount" validate={[required(), minValue(0), maxValue(999), validateInteger]} defaultValue={0} step={1} disabled />
        <div className="helper-text">* Minimum order amount for code to be valid:</div>
        <NumberInput source="minimumOrderAmount" validate={[required(), minValue(0), maxValue(999), validateInteger]} defaultValue={0} step={1} disabled />
        <div className="helper-text">* Maximum order amount for code to be valid:</div>
        <NumberInput source="maximumOrderAmount" validate={[required(), minValue(0), validateInteger]} defaultValue={0} step={1} disabled />
        <div className="helper-text">* Maximum times that this code can be used before becoming invalid:</div>
        <NumberInput source="maximumUses" validate={[required(), minValue(0)]} defaultValue={0} disabled />
        <div className="helper-text">* The categories for which the promotional code will be valid:</div>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              source="categoriesIds"
              reference="categories"
            >
              <SelectArrayInput optionText="categoryName" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
        <div className="helper-text">* The subcategories for which the promotional code will be valid:</div>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              source="subcategoriesIds"
              reference="subcategories"
              filter={{ categoryId: formData && formData.categoryId }}
            >
              <SelectArrayInput optionText="subcategoryName" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
        <SelectInput source="status" choices={PROMOTIONAL_CODES_STATUS} validate={[required()]} defaultValue="INACTIVE" />
        <RichTextInput
          source="adminDescription"
          fullWidth
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={'large'} />
              <FormatButtons size={'large'} />
              <AlignmentButtons size={'large'} />
              <ListButtons size={'large'} />
              <LinkButtons size={'large'} />
              <QuoteButtons size={'large'} />
              <ClearButtons size={'large'} />
            </RichTextInputToolbar>
          }
        />
        <div className="helper-text">* This info is for internal communication only and will not be visible on the site</div>
      </SimpleForm>
    </Create>
  )
};
