
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import styles from './styles.module.scss';

const ClearanceProductsWidget = () => {

  const dataProvider = useDataProvider();
  const [clearaceCount, setClearanceCount] = useState(0);

  useEffect(() => {
    const GetVanSlots = async () => {
      try {
        const res = await dataProvider.getList("products",
          {
            filter: {
              "seasonClearance": true,
              "productStatusName": "UNDER_SELL"
            },
            pagination: { page: 1, perPage: 1 },
          },
        );
        setClearanceCount(res?.total || res?.data?.length || 0);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetVanSlots();
  }, [dataProvider]);

  return (
    <div className={styles.countText}>
      {clearaceCount}
    </div>
  );
};

export default ClearanceProductsWidget;