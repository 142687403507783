import React from 'react';
import { Resource } from 'react-admin';
import RedeemIcon from '@mui/icons-material/Redeem';

import {
  PromotionalCodeList,
  PromotionalCodeEdit,
  PromotionalCodeCreate
} from './views';

const AllPromotionalCodes = () => {
  return (
    <Resource
      name="promotional-codes"
      icon={RedeemIcon}
      options={{ label: 'Promotional Codes' }}
      list={PromotionalCodeList}
      edit={PromotionalCodeEdit}
      create={PromotionalCodeCreate}
    />
  );
}

export default AllPromotionalCodes;
