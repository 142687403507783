import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  SingleFieldList,
  TextField,
  TopToolbar,
  usePermissions,
  DateField,
  ChipField,
  ReferenceArrayField
} from 'react-admin';

import { PROMOTIONAL_CODES_STATUS } from 'src/utils/promotionalCode';

import { Chip } from '@mui/material';

const PromotionalCodeListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <CreateButton />
      {permissions === 'SUPER_ADMIN' && (<ExportButton maxResults={99999999} />)}
    </TopToolbar>
  );
};

const renderChip = (record) => {
  if (!record) {
    return null;
  }

  const value = record.status;
  let chipProps = {
    label: PROMOTIONAL_CODES_STATUS.find(status => status?.id === value)?.name || value,
    size: "large", // Default size, can be customized
  };

  return <Chip {...chipProps} />;
};

export const PromotionalCodeList = props => {

  const Code = ({ record }) => {
    return <div><code>{record.code}</code></div>;
  }

  return (
    <List
      actions={<PromotionalCodeListActions />}
      perPage={25}
      {...props}
    >
      <Datagrid
        rowClick={false}
        bulkActionButtons={false}
      >
        <TextField source="id" />
        <FunctionField render={record => record && <Code record={record} />} label="Code" />
        <ChipField source="discountPercentage" label="Discount %"/>
        <FunctionField render={record => renderChip(record)} label="Status" />
        <EditButton label="Edit" variant="contained"/>
        <ReferenceArrayField source="categoriesIds" reference="categories" sortable={false} label="Category">
          <SingleFieldList>
            <ChipField source="categoryName" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField source="subcategoriesIds" reference="subcategories" sortable={false} label="Subcategory">
          <SingleFieldList>
            <ChipField source="subcategoryName" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="startDate" showTime label="Start Date" />
        <DateField source="endDate" showTime label="End Date" />
        <DateField source="createdAt" showTime label="Created" />
        <DateField source="lastUpdatedAt" showTime label="Updated" />
      </Datagrid>
    </List>
  )
};
