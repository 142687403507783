import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import styles from './styles.module.scss';

const NoPositionWidget = () => {
  const dataProvider = useDataProvider();
  const [noPositionCount, setNoPositionCount] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const GetVanSlots = async () => {
      setLoading(true);
      try {
        const res = await dataProvider.getList("products", {
          filter: {
            "productStatusName": "UNDER_SELL",
            "productFilters.inStoreDisplay": "false",
            "sort": "storagePosition,desc"
          },
          pagination: { page: 1, perPage: 999 },
        });

        // Count how many products have no storage position
        const noPositionCount = res?.data?.reduce((count, product) => {
          if (!product.storagePosition) {
            return count + 1;
          }
          return count;
        }, 0);
        setNoPositionCount(noPositionCount || 0);
      } catch (error) {
        console.warn('Error: ', error);
      } finally {
        setLoading(false);
      }
    };

    GetVanSlots();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  return (
    <div className={loading ? styles.loadingText : styles.countText}>
      {loading ? "Loading..." : noPositionCount}
    </div>
  );
};

export default NoPositionWidget;