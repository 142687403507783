import React, { useEffect, useState } from 'react';
import authProvider from '../services/auth';
import { useRecordContext } from 'react-admin';

export const getEnvironment = () => {
  // When changing environment also change ProductAttributes category IDs!
  const hostname = window !== "undefined" && window.location.hostname;
  if (hostname.includes("localhost")) {
    return "PRODUCTION";
  } else if (hostname.includes("test.cirkel.gr")) {
    return "TEST";
  } else if (hostname.includes("stage.cirkel.gr") || hostname.includes("cirkel.gr")) {
    return "PRODUCTION";
  }
  // return "DEV";
}

/** DEV **/
const BACKEND_DEV_BASE_URL             = "http://localhost";
const PRODUCT_DEV_PUBLIC_URL           = `${BACKEND_DEV_BASE_URL}:8080/admin`;
const AUTHENTICATION_DEV_URL           = `${BACKEND_DEV_BASE_URL}:8085/auth`;
const PAYMENT_DEV_URL                  = `${BACKEND_DEV_BASE_URL}:8082/pay/admin`;

/** TEST **/
const BACKEND_TEST_BASE_URL            = "https://test.cirkel.gr";
const PRODUCT_TEST_PUBLIC_URL          = `${BACKEND_TEST_BASE_URL}:8080/admin`;
const AUTHENTICATION_TEST_URL          = `${BACKEND_TEST_BASE_URL}:8085/auth`;
const PAYMENT_TEST_URL                 = `${BACKEND_TEST_BASE_URL}:8082/pay/admin`;

/** PRODUCTION **/
const BACKEND_PRODUCTION_BASE_URL      = "https://server.cirkel.gr";
const PRODUCT_PROD_PUBLIC_URL          = `${BACKEND_PRODUCTION_BASE_URL}/admin`;
const AUTHENTICATION_PRODUCTION_URL    = `${BACKEND_PRODUCTION_BASE_URL}/auth`;
const PAYMENT_PRODUCTION_URL           = `${BACKEND_PRODUCTION_BASE_URL}/pay/admin`;

export const getAdminUrl = () => {
  const environment = getEnvironment();
  switch (environment) {
    case "DEV":
      return PRODUCT_DEV_PUBLIC_URL;
    case "TEST":
      return PRODUCT_TEST_PUBLIC_URL;
    case "PRODUCTION":
    default:
      return PRODUCT_PROD_PUBLIC_URL;
  }
}

export const getAuthUrl = () => {
  switch (getEnvironment()) {
    case "DEV":
      return AUTHENTICATION_DEV_URL;
    case "TEST":
      return AUTHENTICATION_TEST_URL;
    case "PRODUCTION":
    default:
      return AUTHENTICATION_PRODUCTION_URL;
  }
}

export const getPaymentUrl = () => {
  switch (getEnvironment()) {
    case "DEV":
      return PAYMENT_DEV_URL;
    case "TEST":
      return PAYMENT_TEST_URL;
    case "PRODUCTION":
    default:
      return PAYMENT_PRODUCTION_URL;
  }
}

export const adminUrl = getAdminUrl();
export const authUrl = getAuthUrl();
export const paymentUrl = getPaymentUrl();

export const s3Url = resource => {
  if (resource === 'products') {
    return `https://cirkel-products-photos.s3.eu-central-1.amazonaws.com`;
  }
  return `https://cirkel-${resource}-photos.s3.eu-central-1.amazonaws.com`
};

export const BackgroundImageField = (props) => {
  const record = useRecordContext();
  if (props.firstOf) {
    return (
      <div
        className="background-image"
        style={{ backgroundImage: `url(${s3Url(props.resource)}/${record.uuid || record.id}/1.jpeg)`}}
      >
      </div>
    );
  }
  return <div className="background-image" style={{ backgroundImage: `url(${props.record[props.source]})`}}></div>;
}

export const ShipmentDeliveryType = () => {
  const record = useRecordContext();

  return record && record.shipmentTime && record.shipmentTime.id ? (
    <div>Delivery type: <b>{record.shipmentTime.id === 1 ? 'Cirkel store pickup' : 'Delivery'}</b></div>
  ) : (
    <div>No delivery type information</div>
  );
};

export const AddressSnapshot = () => {
  const record = useRecordContext();
  return (
    <table>
      <thead>
        <tr>
          <th>Street</th>
          <th>Street number</th>
          <th>City</th>
          <th>Country</th>
          <th>Post code</th>
          <th>Country code</th>
          <th>Phone number</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{record?.userAddressSnapshot?.street}</td>
          <td>{record?.userAddressSnapshot?.streetNumber}</td>
          <td>{record?.userAddressSnapshot?.city}</td>
          <td>{record?.userAddressSnapshot?.country}</td>
          <td>{record?.userAddressSnapshot?.postcode}</td>
          <td>{record?.userAddressSnapshot?.countryCode}</td>
          <td>{record?.userAddressSnapshot?.phoneNumber}</td>
        </tr>
      </tbody>
    </table>
  );
};

export const VerifyEmail = () => {
  const record = useRecordContext();
  const sendEmail = e => {
    e.preventDefault();
    authProvider.sendVerificationEmail(record.email);
  }
  return record && !record.emailVerified && <button onClick={sendEmail}>Resend email</button>;
}

export const formatDateMinus2Hours = date => {
  const newDate = new Date(date);
  return newDate.toLocaleString('el-GR');
}

export function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export function formatUTCDate(dateString) {
  const date = new Date(dateString);
  const hours = addZeroBefore(date.getUTCHours());
  const minutes = addZeroBefore(date.getMinutes());

  return `${date.toLocaleDateString()} ${hours}:${minutes}`;
}

export const GetSlotFormatedDate = () => {
  const record = useRecordContext();
  const slot = (record && record.shipmentTime) || (record.shipments && record.shipment?.bagShipmentDto?.shipmentTime);
  return !slot
    ? null
    : formatUTCDate(slot.startDate);
}

export const availableLanguages = [
  { id: 'en', name: '🇺🇸 English' },
  { id: 'gr', name: '🇬🇷 Greek' },
];

export const availablePostalCodes = [
  { id: '54', name: '54X XX - Κέντρο' },
  { id: '55', name: '55X XX - Ανατολικά' },
  { id: '56', name: '56X XX - Δυτικά' },
  { id: '57', name: '57X XX - Υπόλοιπος Νομός' },
];

export const getProductWeightsByCategory = (categoryId) => {
  return productWeights.map((productWeight) => {
    if (categoryId === productWeight.categoryId) {
      return productWeight;
    }
    return null;
  });
}

export const productWeights = [
  { categoryId: 1, label: "T-Shirt-165gr", value: 0.165 },
  { categoryId: 1, label: "Shirt-250gr", value: 0.250 },
  { categoryId: 1, label: "Summer jacket woman-500gr", value: 0.500 },
  { categoryId: 1, label: "Summer jacket man-600gr", value: 0.600 },
  { categoryId: 1, label: "Winter jacket-1180gr", value: 1.180 },
  { categoryId: 1, label: "Winter jacket-1550gr", value: 1.550 },
  { categoryId: 1, label: "Blazer-800gr", value: 0.800 },
  { categoryId: 1, label: "Pants/Jeans-530gr", value: 0.530 },
  { categoryId: 1, label: "Shorts-340gr", value: 0.340 },
  { categoryId: 1, label: "Skirt-300gr", value: 0.300 },
  { categoryId: 2, label: "Belt-140gr", value: 0.140 },
  { categoryId: 1, label: "Pullover man-700gr", value: 0.700 },
  { categoryId: 1, label: "Pullover woman-500gr", value: 0.501 },
  { categoryId: 4, label: "Book (100page softcover)-200gr", value: 0.200},
  { categoryId: 4, label: "Book (150page softcover)-250gr", value: 0.251 },
  { categoryId: 4, label: "Book (300 page softcover)-350gr", value: 0.350 },
  { categoryId: 4, label: "Book (450page + hardcover)-1kg", value: 1.000 },
  { categoryId: 2, label: "Bag medium size-770gr", value: 0.770},
  { categoryId: 2, label: "Clutch bag-400gr", value: 0.400 },
  { categoryId: 2, label: "Backpack-530gr", value: 0.531 },
  { categoryId: 2, label: "Wallet-50gr", value: 0.050 },
  { categoryId: 2, label: "Summer hat-100gr", value: 0.100 },
  { categoryId: 2, label: "Beenie-150gr", value: 0.150 },
  { categoryId: 2, label: "Winter scarf-200gr", value: 0.201 },
  { categoryId: 2, label: "Summer scarf-50gr", value: 0.051 },
  { categoryId: 1, label: "Dress-400gr", value: 0.401 },
  { categoryId: 1, label: "Suit-1300gr", value: 1.300 },
  { categoryId: 1, label: "Kids pants-200gr", value: 0.202 },
  { categoryId: 1, label: "Kids jacket-550gr", value: 0.550 },
  { categoryId: 1, label: "Onesie-70gr", value: 0.070 },
  { categoryId: 1, label: "Kids dresses and dungarees-120gr", value: 0.120 },
  { categoryId: 2, label: "Kids bags-300gr", value: 0.301 },
  { categoryId: 2, label: "Hair accessory-50gr", value: 0.052 },
  { categoryId: 1, label: "Kids pullover-250gr", value: 0.252 },
  { categoryId: 2, label: "Kids blanket-500gr", value: 0.502},
  { categoryId: 2, label: "Soft toy-200gr", value: 0.203 },
  { categoryId: 1, label: "Kids Tshirt/shirt-100gr", value: 0.101 },
];

export const availableProductStatus = [
  { id: 'CLEANING', name: '🟡 Cleaning' },
  { id: 'CREATED', name: '🔵 Created' },
  { id: 'DONATED', name: '🔴 Donated' },
  { id: 'EXPIRED', name: '🟡 Expired', disabled: true },
  { id: 'FIXING', name: '🟡 Fixing' },
  { id: 'PHOTOGRAPHING', name: '🟡 Photographing' },
  { id: 'PRE_LISTING', name: '🟠 Pre-listing' },
  { id: 'RECYCLED', name: '🔴 Recycled', disabled: true },
  { id: 'REJECTED', name: '🔴 Rejected' },
  { id: 'RESERVED', name: '🟠 Reserved' },
  { id: 'SOLD', name: '🔵 Sold' },
  { id: 'UNDER_SELL', name: '🟢 Under Sell' },
];

export const availableOrderStatus = [
  { id: 'CREATED', name: 'CREATED' },
  { id: 'REJECTED', name: 'REJECTED' },
  { id: 'EXPIRED', name: 'EXPIRED' },
  { id: 'PAID', name: 'PAID' },
  { id: 'SHIPPED', name: 'SHIPPED' },
  { id: 'PENDING_PAYMENT', name: 'PENDING_PAYMENT' },
  { id: 'READY_FOR_PICKUP', name: 'READY_FOR_PICKUP' },
];

export const availablePickupStatus = [
  { id: 'CREATED', name: '⚪ Created' },
  { id: 'CANCELLED', name: '⭕ Cancelled' },
  { id: 'PICKED_UP', name: '🔵 Picked up' },
  { id: 'FAILED', name: '🔴 Failed' },
  { id: 'EVALUATION', name: '🟡 Evaluation' },
  { id: 'COMPLETED', name: '🟢 Completed' },
];

export const pickupStatusSymbols = [
  { id: 'CREATED', pic: '⚪' },
  { id: 'CANCELLED', pic: '⭕' },
  { id: 'PICKED_UP', pic: '🔵' },
  { id: 'FAILED', pic: '🔴' },
  { id: 'EVALUATION', pic: '🟡' },
  { id: 'COMPLETED', pic: '🟢' },
];

export const productStatusSymbols = [
  { id: 'CREATED', pic: '🔵 Created' },
  { id: 'DONATED', pic: '🔴 Donated' },
  { id: 'RECYCLED', pic: '🔴 Recycled' },
  { id: 'REJECTED', pic: '🔴 Rejected' },
  { id: 'RESERVED', pic: '🟠 Reserved' },
  { id: 'PHOTOGRAPHING', pic: '🟡 Photographing' },
  { id: 'CLEANING', pic: '🟡 Cleaning' },
  { id: 'FIXING', pic: '🟡 Fixing' },
  { id: 'FAILED', pic: '🔴 Failed' },
  { id: 'SOLD', pic: '🔵 Sold' },
  { id: 'UNDER_SELL', pic: '🟢 Under sell' },
];

export const availableGenders = [
  { id: 'MALE', name: '👨 Male', short: '👨' },
  { id: 'FEMALE', name: '👩 Female', short: '👩' },
  { id: 'UNISEX', name: '☯ Unisex', short: '👫' },
  { id: 'KID', name: '👶 Kid', short: '👶' }
];

export const countryCodes =  [
  { id: "00376" , name: "Andorra (+376)" },
  { id: "0043" , name: "Austria (+43)" },
  { id: "0032" , name: "Belgium (+32)" },
  { id: "00387" , name: "Bosnia Herzegovina (+387)" },
  { id: "00359" , name: "Bulgaria (+359)" },
  { id: "00385" , name: "Croatia (+385)" },
  { id: "00357" , name: "Cyprus South (+357)" },
  { id: "0042" , name: "Czech Republic (+42)" },
  { id: "0045" , name: "Denmark (+45)" },
  { id: "00372" , name: "Estonia (+372)" },
  { id: "00358" , name: "Finland (+358)" },
  { id: "0033" , name: "France (+33)" },
  { id: "007880" , name: "Georgia (+7880)" },
  { id: "0049" , name: "Germany (+49)" },
  { id: "00350" , name: "Gibraltar (+350)" },
  { id: "0030" , name: "Greece (+30)" },
  { id: "0036" , name: "Hungary (+36)" },
  { id: "00354" , name: "Iceland (+354)" },
  { id: "00353" , name: "Ireland (+353)" },
  { id: "0039" , name: "Italy (+39)" },
  { id: "00371" , name: "Latvia (+371)" },
  { id: "00370" , name: "Lithuania (+370)" },
  { id: "00352" , name: "Luxembourg (+352)" },
  { id: "00356" , name: "Malta (+356)" },
  { id: "00373" , name: "Moldova (+373)" },
  { id: "00377" , name: "Monaco (+377)" },
  { id: "0031" , name: "Netherlands (+31)" },
  { id: "00389" , name: "North Macedonia (+389)" },
  { id: "0047" , name: "Norway (+47)" },
  { id: "0048" , name: "Poland (+48)" },
  { id: "00351" , name: "Portugal (+351)" },
  { id: "0040" , name: "Romania (+40)" },
  { id: "007" , name: "Russia (+7)" },
  { id: "00378" , name: "San Marino (+378)" },
  { id: "00381" , name: "Serbia (+381)" },
  { id: "00421" , name: "Slovak Republic (+421)" },
  { id: "00386" , name: "Slovenia (+386)" },
  { id: "0034" , name: "Spain (+34)" },
  { id: "0046" , name: "Sweden (+46)" },
  { id: "0041" , name: "Switzerland (+41)" },
  { id: "0090" , name: "Turkey (+90)" },
  { id: "00380" , name: "Ukraine (+380)" },
  { id: "00379" , name: "Vatican City (+379)" },
];

export const userRoles = [
  { id: 'ROLE_SUPER_ADMIN', name: '🔴 Super Admin' },
  { id: 'ROLE_ADMIN', name: '🟡 Admin' },
  { id: 'ROLE_CLIENT', name: '🟢 Client' }
];

export const getHeaders = (type) => {
  const jwt = localStorage.getItem('token');
  const headers = {
    mode: 'cors',
  };
  if (type !== 'none') {
    headers['Content-Type'] = 'application/json';
  }
  if (jwt) {
    headers['Authorization'] = `Bearer ${jwt}`
  }

  return headers;
}

export const TotalField = props => {
  const [total, setTotal] = useState('-');
  useEffect(() => {
    fetch(`${adminUrl}${props.query}${props.record[props.variable]}`, { method: 'GET', headers: getHeaders() }).then(res => res.json()).then(res => {
      setTotal(res.total);
    });
  }, [props]);

  return <div>{total}</div>;
}

export const refreshToken = () => {
  return fetch(`${authUrl}/users/refresh`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  })
    .then(res => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error('Error');
      }
      return res.json();
    })
    .then(res => {
      localStorage.setItem('token', res.jwt);
    })
    .catch(() => {
      localStorage.removeItem('token');
      const loginUrl = `${window.origin}/#/login`;
      if (window.location.href !== loginUrl) {
        window.location.replace(loginUrl);
      }
    });
};

export const getNewPhotos = async data => {
  const fileUploads = [
    { name: 'image', newName: 'newImage' },
    { name: 'profileImage', newName: 'newProfileImage' },
  ];

  for (let fileUpload of fileUploads) {
    if (data[fileUpload.newName]) {
      const imageValue = await convertFileToBase64(data[fileUpload.newName]);
      data[fileUpload.name] = imageValue;
    }
  }

  return data;
}

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });
}

export const BankIbanCodes = {
  '010': 'ΤΡΑΠΕΖΑ ΤΗΣ ΕΛΛΑΔΑΣ (ΟΧΙ ΓΙΑ ΠΙΣΤΩΣΕΙΣ ΜΙΣΘΩΝ)',
  '011': 'ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ ΤΗΣ ΕΛΛΑΔΟΣ Α.Ε.',
  '014': 'ΑΛΦΑ ΤΡΑΠΕΖΑ Α.Ε.',
  '016': 'ATTICA BANK, ΑΝΩΝΥΜΗ ΤΡΑΠΕΖΙΚΗ ΕΤΑΙΡΕΙΑ' ,
  '017': 'ΠΕΙΡΑΙΩΣ Α.Ε.',
  '026': 'ΕUROBANK ΑΕ',
  '034': 'OPTIMA BANK Α.Ε.',
  '056': 'AEGEAN BALTIC BANK Α.Τ.Ε.',
  '057': 'VIVABANK ΜΟΝΟΠΡΟΣΩΠΗ ΑΝΩΝΥΜΗ ΤΡΑΠΕΖΙΚΗ ΕΤΑΙΡΕΙΑ',
  '069': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΧΑΝΙΩΝ ΣΥΝ.Π.Ε.',
  '071': 'HSBC Continental Europe, Greece',
  '072': 'UNICREDIT BANK A.G.',
  '075': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΗΠΕΙΡΟΥ ΣΥΝ.ΠΕ.',
  '084': 'CITIBANK EUROPE PLC (CEP)',
  '087': 'ΠΑΓΚΡΗΤΙΑ ΤΡΑΠΕΖΑ ΑΝΩΝΥΜΗ ΕΤΑΙΡΕΙΑ',
  '088': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΔΡΑΜΑΣ ΣΥΝ.Π.Ε. (Πρώην ΕΒΡΟΥ)',
  '089': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΚΑΡΔΙΤΣΑΣ ΣΥΝ.Π.Ε.',
  '091': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΘΕΣΣΑΛΙΑΣ ΣΥΝ.Π.Ε.',
  '094': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ (πρώην Πιερίας)',
  '095': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΔΡΑΜΑΣ ΣΥΝ.Π.Ε.',
  '097': 'ΤΑΜΕΙΟ ΠΑΡΑΚΑΤΑΘΗΚΩΝ ΚΑΙ ΔΑΝΕΙΩΝ',
  '099': 'ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ ΤΡΑΠΕΖΑ ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ',
  '109': 'T.C. ZIRAAT BANKASI A.S.',
  '116': 'PROCREDIT BANK (BULGARIA) EAD',
  '199': 'ΕΛΛΗΝΙΚΑ ΤΑΧΥΔΡΟΜΕΙΑ',
  '701': 'Viva Payment Services S.A.'
}

export const parseSKU = (value) => {
  return value.replace(/[^a-fA-F0-9]/g, "")
}

export const parseProductName = (value) => {
  return value.replace(/[^a-zA-Zα-ωΑ-Ω0-9ΆΈΌΊΉΎάέόίήύ.&'\\(/\\)\- ]/g, "")
}

export const parseUID = (value) => {
  return value.replace(/[^a-zA-Z0-9]/g, "")
}

export const parseName = (value) => {
  return value.replace(/[^a-zA-Z ]/g, "");
}

export const parsePickupId = (value) => {
  return value.replace(/[^0-9]/g, "");
}

export const numberToEmoji = {
  '0': "1️⃣",
  '1': "1️⃣",
  '2': "2️⃣",
  '3': "3️⃣",
  '4': "4️⃣",
  '5': "5️⃣",
  '6': "6️⃣",
  '7': "7️⃣",
  '8': "8️⃣",
  '9': "9️⃣"
};

export const parseDateWithSeconds = (date) => {
  if (!date) return null;
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = "00.000Z";
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};
