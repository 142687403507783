import React from 'react';
import {
  Edit,
  Labeled,
  TextField,
  TextInput,
  NumberInput,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  SelectInput,
  TabbedForm,
  FormTab
} from 'react-admin';

import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons
} from 'ra-input-rich-text';

import { GetCreatedByUser, GetLastUpdatedByUser } from 'src/resources/PromotionalCodes/common';
import { PROMOTIONAL_CODES_STATUS } from 'src/utils/promotionalCode';
import { validatePromotionalCode, validateInteger } from 'src/resources/PromotionalCodes/common';

import styles from 'src/resources/PromotionalCodes/styles.module.scss';

export const PromotionalCodeEdit = props => {

  return (
    <Edit {...props} redirect="list">
      <TabbedForm warnWhenUnsavedChanges  redirect="false">
        <FormTab label="Essentials">
          <Labeled label="Promotional Code ID:">
            <TextField source="id" />
          </Labeled>
          <div className="helper-text">* User-friendly name that will be communicated and used by Users.</div>
          <TextInput
            source="code"
            id="promotionalCodeCode"
            validate={[required(), minLength(6), maxLength(50), validatePromotionalCode]}
            resettable
          />
          <div className="helper-text">* The date/time the promotion code starts and stops to be valid:</div>
          <div className={styles.rowContainer}>
            <DateTimeInput label='Start Date:' source='startDate' locales="el-GR" />
            <DateTimeInput label='End Date:' source='endDate' locales="el-GR" />
          </div>
          <div className="helper-text">* The percentage of discount that the promotional code will apply:</div>
          <NumberInput source="discountPercentage" validate={[required(), minValue(0), maxValue(99), validateInteger]} step={1} />
          <div className="helper-text">* Other eligibility options:</div>
          <div className={styles.rowContainer}>
            <NumberInput source="discountMaxAmount" validate={[required(), minValue(0), maxValue(999), validateInteger]} defaultValue={0} step={1}  disabled />
            <NumberInput source="minimumOrderAmount" validate={[required(), minValue(0), maxValue(999), validateInteger]} defaultValue={0} step={1} disabled />
            <NumberInput source="maximumOrderAmount" validate={[required(), minValue(0), validateInteger]} defaultValue={0} step={1} disabled />
          </div>
          <div className="helper-text">* Maximum amount of discount (Coming Soon!)</div>
          <div className="helper-text">* Minimum order amount (Coming Soon!)</div>
          <div className="helper-text">* Maximum order amount (Coming Soon!)</div>
          <div className="helper-text">* Maximum times that this code can be used before becoming invalid (Coming Soon!)</div>
          <NumberInput source="maximumUses" validate={[required(), minValue(0)]} defaultValue={0} disabled />
          <div className="helper-text">* The categories and subcategories for which the promotional code will be valid:</div>
          <div className={styles.rowContainer}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <ReferenceArrayInput
                  source="categoriesIds"
                  reference="categories"
                >
                  <SelectArrayInput optionText="categoryName" />
                </ReferenceArrayInput>
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <ReferenceArrayInput
                  source="subcategoriesIds"
                  reference="subcategories"
                  filter={{ categoryId: formData && formData.categoryId }}
                >
                  <SelectArrayInput optionText="subcategoryName" />
                </ReferenceArrayInput>
              )}
            </FormDataConsumer>
          </div>
          <SelectInput source="status" choices={PROMOTIONAL_CODES_STATUS} validate={[required()]} />
        </FormTab>
        <FormTab label="Administration">
          <RichTextInput
            source="adminDescription"
            fullWidth
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect size={'large'} />
                <FormatButtons size={'large'} />
                <AlignmentButtons size={'large'} />
                <ListButtons size={'large'} />
                <LinkButtons size={'large'} />
                <QuoteButtons size={'large'} />
                <ClearButtons size={'large'} />
              </RichTextInputToolbar>
            }
          />
          <div className="helper-text">* This info is for internal communication only and will not be visible on the site</div>
          <div className="helper-text">* Created By:</div>
          <GetCreatedByUser />
          <div className="helper-text">* Last Update By:</div>
          <GetLastUpdatedByUser />
          <div className="helper-text">* Timestamps:</div>
          <DateTimeInput label='Creation Date:' source='createdAt' locales="el-GR" disabled />
          <DateTimeInput label='Last Update Date:' source='lastUpdatedAt' locales="el-GR" disabled />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};
