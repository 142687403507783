import React, { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

export const GetCreatedByUser = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [userFullname, setFullName] = useState('');

  useEffect(() => {
    if (record?.createdByUserId) {
      const fetchUserFullname = async () => {
        try {
          const res = await dataProvider.getOne('users', { id: record.createdByUserId });
          setFullName(`${res?.data?.firstName} ${res?.data?.lastName}`);
        } catch (error) {
          console.warn('Error: ', error);
        }
      };

      fetchUserFullname();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span>{userFullname}</span>;
};

export const GetLastUpdatedByUser = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [userFullname, setFullName] = useState('');

  useEffect(() => {
    if (record?.lastUpdatedByUserId) {
      const fetchUserFullname = async () => {
        try {
          const res = await dataProvider.getOne('users', { id: record.createdByUserId });
          setFullName(`${res?.data?.firstName} ${res?.data?.lastName}`);
        } catch (error) {
          console.warn('Error: ', error);
        }
      };

      fetchUserFullname();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span>{userFullname}</span>;
};

// Custom validation function to allow only numbers and Latin letters
export const validatePromotionalCode = (value) => {
  const regex = /^[a-zA-Z0-9-]*$/;
  return regex.test(value) ? undefined : 'Only numbers, Latin letters and dashes are allowed. Spaces are not allowed!';
};

export const validateInteger = (value) => {
  const regex = /^\d+$/;
  return regex.test(value) ? undefined : 'Only integers are allowed';
};
